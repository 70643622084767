.cityname{
    font-family: 'Inter-Medium';
}
.weatherStatus ul li{
    @apply flex flex-col items-center gap-3;   
}
.weatherStatus ul li p{
    @apply mt-2;
}
.stockList ul li{
    @apply bg-lotion flex justify-between items-center rounded p-6;
    border: 0.2px solid rgb(94 94 94 / 20%);
}
.stockList ul li span{
    @apply text-sm leading-[17px] text-celticBlue font-medium;
    font-family: 'Inter-Medium';
}
.stockList ul li p{
    @apply text-sm leading-[17px] text-black font-medium;
    font-family: 'Inter-Medium';
}
.rates h4{
    @apply text-base leading-[19px];
    font-family: 'Inter-SemiBold';
} 
.rates h5{
    @apply text-[10px] leading-[12px];
    font-family: 'Inter-Medium';
}
.rates .red h4{
    @apply text-coralRed;
} 
.rates .green h4{
    @apply text-seaGreen;
} 
.rates .red h5{
    @apply text-coralRed;
} 
.rates .green h5{
    @apply text-seaGreen;
}
.leaderInfo h4{
    @apply text-sm leading-[17px] text-black font-medium;
    font-family: 'Inter-Medium';
}
.leaderInfo p{
    @apply text-xs leading-[15px] text-blackCoral font-medium pr-12;
    font-family: 'Inter-Regular';
}
.leaderInfo ul li{
    @apply bg-lotion rounded flex p-[10px] gap-[17px];
    border: 0.2px solid rgb(94 94 94 / 20%);
}
.leaderInfo .content{
    @apply flex flex-col gap-2;
}
.leaderInfo img{
    max-width: initial;
}