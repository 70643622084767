/* @import url('./global.css'); */
/* @import url('./fonts.css'); */
/* @tailwind base;
@tailwind components;
@tailwind utilities; */
@import url('../fonts/fonts.css');

body {
    font-family: "Inter-Regular";
    @apply box-border w-full m-0 bg-alto;
  }
.custom-container{
    @apply !max-w-[1920px] mx-auto lg:px-16 md:px-4;
}
.content_card{
  border: 1px solid rgb(94 94 94 / 20%);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background: #fff;  
  padding: 20px 22px 76px 22px;
}
.content-title{
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  font-family: 'Inter-Medium';
}